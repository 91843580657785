import React from 'react';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';
import Cta from '../partials/Cta';
import Hero from '../partials/Hero';
import Features from '../partials/Features';

const Index = () => {
  return (
    <Layout noPadding showBorder={false}>
      <Seo title="Copybara" path="/" />
      <Hero />
      <Features />
      <Cta />
    </Layout>
  );
};

export default Index;
