import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Hero = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            appUrl
          }
        }
      }
    `
  );
  const { t } = useTranslation();

  return (
    <section className="relative">
      <div className="px-4 sm:px-6 pb-12 md:pb-20">
        <div className="text-center">
          <h1 className="mb-4 text-3xl md:text-6xl font-extrabold md:leading-tight">
            <Trans t={t}>
              Manage Your Copy
              <br />
              for Multiple Platforms
            </Trans>
          </h1>
          <div className="mx-auto max-w-3xl">
            <p className="mb-8 text-xl text-gray-600">
              <Trans t={t}>
                {site.siteMetadata.title} helps you craft consistent messaging
                for multiple platforms,
                <br />
                with the flexibility to distribute them as part of your usual
                workflows.
              </Trans>
            </p>
            <div className="sm:flex sm:justify-center mx-auto max-w-xs sm:max-w-none">
              <div>
                <a
                  className="mb-3 btn btn-solid btn-lg btn-oxford-blue"
                  href={site.siteMetadata.appUrl}
                >
                  {t('Start free trial')}
                </a>
                <p className="text-sm">
                  {t('14-day free trial. No credit card required.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
