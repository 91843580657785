import React from 'react';
import { useTranslation } from 'react-i18next';

import ColorSwatchIcon from '../assets/svg/color-swatch.svg';
import DownloadIcon from '../assets/svg/download.svg';
import MarkdownIcon from '../assets/svg/markdown.svg';
import PuzzleIcon from '../assets/svg/puzzle.svg';
import TemplateIcon from '../assets/svg/template.svg';
import WaveBottom from '../assets/svg/wavebottom.svg';
import WebhookIcon from '../assets/svg/webhook.svg';

const Features = () => {
  const { t } = useTranslation();

  return (
    <section className="relative bg-oxford-blue">
      <div className="relative px-4 sm:px-6 mx-auto max-w-6xl">
        <div className="py-12 md:py-20">
          <h2 className="pb-10 md:pb-16 mb-4 text-3xl md:text-4xl text-center text-white">
            {t('Features')}
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 items-stretch mx-auto max-w-sm md:max-w-2xl lg:max-w-none">
            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <DownloadIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Batch import')}
              </h4>
              <p className="text-center text-gray-600">
                {t('Import templates and substitutions from other sources.')}
              </p>
              <p className="text-sm text-center text-gray-600">
                {t('CSV currently. More soon.')}
              </p>
            </div>

            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <MarkdownIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Markdown support')}
              </h4>
              <p className="text-center text-gray-600">
                {t('Use markdown syntax in your substitutions and templates.')}
              </p>
            </div>

            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <ColorSwatchIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Multiple formats')}
              </h4>
              <p className="text-center text-gray-600">
                {t(
                  'Generate copy in plain text, markdown, HTML, or rich text.'
                )}
              </p>
            </div>

            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <TemplateIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Presets')}
              </h4>
              <p className="text-center text-gray-600">
                {t(
                  'Use character limit presets for popular services to ensure your copy fits.'
                )}
              </p>
            </div>

            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <WebhookIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Webhook support')}{' '}
                <sup className="py-1 px-2 text-xs text-white bg-oxford-blue rounded-full">
                  Plus
                </sup>
              </h4>
              <p className="text-center text-gray-600">
                {t(
                  'Use webhooks to publish copy to third-party and integration services.'
                )}
              </p>
            </div>

            <div className="flex relative flex-col items-center p-6 bg-white rounded shadow-xl">
              <PuzzleIcon className="w-14 h-14 text-oxford-blue" />
              <h4 className="mb-1 text-xl font-bold tracking-tight leading-snug">
                {t('Browser extensions')}
              </h4>
              <p className="text-center text-gray-600">
                {t(
                  'Use extensions to populate forms for services without integration support.'
                )}
              </p>
              <p className="text-sm text-center text-gray-600">
                {t('Coming soon.')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative -mt-12 lg:-mt-24">
        <WaveBottom />
      </div>
    </section>
  );
};

export default Features;
