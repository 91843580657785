import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const Cta = ({
  wrapperClasses = 'relative py-12 md:py-20 px-4 sm:px-6 mx-auto max-w-6xl text-center',
  heading = 'Start your free trial',
  subheading = '14-day free trial. No credit card required.',
  buttonText = 'Get started',
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            appUrl
          }
        }
      }
    `
  );

  return (
    <section className="relative">
      <div className={wrapperClasses}>
        <h2 className="text-3xl md:text-4xl sm:leading-snug md:leading-snug whitespace-pre-line">
          {heading}
        </h2>
        {subheading && (
          <h3 className="mt-0 mb-8 text-xl font-normal text-center text-gray-500">
            {subheading}
          </h3>
        )}
        <a
          className="mt-4 mb-3 btn btn-solid btn-lg btn-oxford-blue"
          href={site.siteMetadata.appUrl}
        >
          {buttonText}
        </a>
      </div>
    </section>
  );
};

export default Cta;
